import React from 'react'
import PropTypes from 'prop-types' 
import { graphql }  from 'gatsby'

import Img from '../components/AtomImg'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'

import H2 from '../components/H2'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Body2 from '../components/Body2'
import SubTitle from '../components/SubTitle2'
import Div from '../components/Div/Div.styles'
import ContainedButton from '../components/ContainedButton'
import Hr from '../components/Hr'
import H3 from '../components/H3'
import ImgCoreLogin from '../images/core-login.svg'
import { h3Style, titleStyle, imageSVG2 } from '../components/styles/InternalPage.styles'
import { btnLink } from '../components/styles/OutlineButton.styles'
import { btnContainedLink } from '../components/styles/ContainedButton.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[34.5, 35, 68, 46]}
            mb={[50]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 400, 450]}
              >
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center', 'center', 'flex-start']}
                textAlign={['center', 'center', 'left']}
              >
                <H2
                  color='black'
                  variant='highEmphasis'
                  mb={[5, 5, 3]}
                  css={titleStyle}
                >
                  Nosso Suporte
                </H2>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  maxWidth={[310, 310, 327, 360]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  order={[2, 2, 1]}
                  mb={[0, 0, 3]}
                  maxWidth={['90%', 422, 504, 504]}
                >
                  A HostDime oferece excelência em todos os níveis de serviço através do uso inovador de tecnologia aplicada a soluções personalizadas para todas as necessidades dos clientes.
                </Body2>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />  
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[40, 50, 60, 90]}
            mb={[40, 50, 60, 90]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '80%', 400, 450]}
              >
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3, 3, 1, 1]}
                  css={h3Style}                  
                >
                  Suporte Técnico
                </H3>
                <H6
                  variant='highEmphasis'
                  mb={[5]}
                  maxWidth={[295, 422, 416, 416]}
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)'
                  }}
                >
                  24 horas, incluindo domingo e feriados
                </H6>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  maxWidth={[305, 305, 305, 336]}
                >
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Div
                  maxWidth={['100%', '100%', 305, 336]}
                >
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    mb={[5]}
                  >
                    Equipe de suporte especializada e multicultural disponível 24x7x365 para uma assistência eficaz e assessoria completa na implementação de sistemas.
                  </Body2>
                </Div>
                <a 
                  href='https://core.hostdime.com.br/support/new'
                  css={btnLink}
                  style={{
                    maxWidth: 230
                  }}
                >
                  abrir um chamado
                </a>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[20, 25, 30, 45]}
            mb={[40, 50, 60, 90]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 400, 450]}
              >
                <Img
                  fluid={data.image3.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3, 3, 1, 1]}
                  css={h3Style}                  
                >
                  Vendas e Suporte Financeiro
                </H3>
                <H6
                  variant='highEmphasis'
                  mb={[5]}
                  maxWidth={[240, 422, 416, 416]}
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)'
                  }}
                >
                  08h às 18h, de segunda à sexta
                </H6>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={[305, 305, 308, 339]}
                >
                  <Img
                    fluid={data.image3.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Div
                  maxWidth={['100%', 422, 416, 416]}
                >
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    align='center'
                    mb={[5]}
                  >
                    Equipe de suporte especializada e multicultural disponível 24x7x365 para uma assistência eficaz e assessoria completa na implementação de sistemas.
                  </Body2>
                </Div>
                <ContainedButton
                  type='btnContainedSmall'
                  Link='/contatos'
                >
                  Fale conosco
                </ContainedButton>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[20, 25, 30, 45]}
            mb={[40, 50, 60, 90]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 400, 450]}
              >
                <Img
                  fluid={data.image4.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3, 3, 1, 1]}
                  css={h3Style}                  
                >
                  Central <br />de Ajuda
                </H3>
                <H6
                  variant='highEmphasis'
                  mb={[5]}
                  maxWidth={['100%', 328, 433, 433]}
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)'
                  }}
                >
                  Simples e Prático.
                </H6>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={[290, 350, 400, 450]}
                >
                  <Img
                    fluid={data.image4.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Div
                  maxWidth={['80%', 422, 416, 416]}
                >
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    mb={[5]}
                  >
                    Encontre respostas para suas dúvidas e artigos de implementação desenvolvidos especialmente para você.
                  </Body2>
                </Div>
                <a
                  href='https://www.hostdime.com.br/ajuda/'
                  target='_blank'
                  css={btnLink}
                  style={{
                    maxWidth: 216
                  }}
                >
                  tire suas dúvidas
                </a>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />        
        <Div
          maxWidth={['100%', 390, 680]}
        >
          <Row
            pt={[50]}
            pb={[50]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <div>
                <Div
                  maxWidth={['100%']}
                  alignItems={['center']}
                  css={imageSVG2}
                  style={{
                    margin: '0 auto',
                    marginBottom: 30
                  }}
                >
                  <img
                    src={ImgCoreLogin} 
                  />
                </Div>
                <H5
                  color='black'
                  variant='highEmphasis'
                  align='center'
                  mb={[1]}
                >
                  Core Login
                </H5>
                <SubTitle
                  align='center'
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)'
                  }}
                  mb={[5]}
                >
                  Acesse seu Painel de Controle
                </SubTitle>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <a
                    href='https://core.hostdime.com.br/'
                    css={btnContainedLink}
                    style={{
                      width: 260,
                      marginBottom: 24
                    }}
                  >
                    IR PARA O LOGIN
                  </a>
                </div>
                <Body2
                  style={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    textAlign: 'center'
                  }}
                >
                  Novo usuário?
                  <a href='https://core.hostdime.com.br/auth/create'>
                    <span
                      style={{
                        color: '#ff5800',
                        fontWeight: 500,
                        marginLeft: 8
                      }}
                    >
                      Crie uma Conta
                    </span>
                  </a>
                </Body2>
              </div>
            </Column>
          </Row>
        </Div>
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='O melhor suporte para você | HostDime Brasil'
        description='Nosso suporte proporciona excelência em soluções personalizadas para todos os tipos de serviços do Data Center, a qualquer hora.'
      />
      <PageTemplate
        title='Nosso Suporte'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
   image1: file(relativePath: { eq: "support/nosso-suporte.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "support/suporte-tecnico.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "support/vendas-suporte-financeiro.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "support/hostdime-knowlegde.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
